export const Input = ({ className, placeHolder, inputRef, ...addProps }) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  return (
    <input
      className={className}
      placeholder={placeHolder}
      autoComplete="off"
      ref={inputRef}
      {...addProps}
      onKeyPress={handleKeyPress}
    />
  )
}