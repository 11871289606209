import React from 'react';

export const BlueMarker = () => {
    return (
        <svg className='blue-marker' width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 10.564C5.65661 10.564 6.30679 10.4346 6.91342 10.1834C7.52005 9.93209 8.07124 9.56379 8.53553 9.0995C8.99983 8.63521 9.36812 8.08401 9.6194 7.47738C9.87067 6.87075 10 6.22057 10 5.56396C10 4.90736 9.87067 4.25718 9.6194 3.65055C9.36812 3.04392 8.99983 2.49272 8.53553 2.02843C8.07124 1.56414 7.52005 1.19584 6.91342 0.944567C6.30679 0.693294 5.65661 0.563965 5 0.563965C3.67392 0.563965 2.40215 1.09075 1.46447 2.02843C0.526784 2.96611 0 4.23788 0 5.56396C0 6.89005 0.526784 8.16182 1.46447 9.0995C2.40215 10.0372 3.67392 10.564 5 10.564ZM4.87111 7.58619L7.64889 4.25285L6.79556 3.54174L4.40667 6.40785L3.17056 5.17119L2.385 5.95674L4.05167 7.62341L4.48167 8.05341L4.87111 7.58619Z" fill="#2D4664" />
        </svg>

    )
}

export const WhiteMarker = () => {
    return (
        <svg className='white-marker' width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5.56396" r="4.5" stroke="#2D4664" />
        </svg>

    )
}