import { Pl } from "./pl";


export const useMask = (
    country,
    countryCode,
    setInputPhone,
    onChange) => {
    // Минимальная позиция для ввода цифр
    const minPosition = countryCode.length;

    const getInputNumbersValue = (input) => {
        return input ? input.replace(/\D/g, '') : ''
    }

    const onPhoneInput = (e) => {
        let input = e.target.value
        let inputNumbersValue = getInputNumbersValue(input)
        let selectionStart = e.target.selectionStart || 0
        let formattedInputValue = ''

        // Ограничиваем количество цифр в маске
        if (inputNumbersValue.length > 11) {
            inputNumbersValue = inputNumbersValue.substring(0, 11)
        }

        if (country === 'PL') formattedInputValue = Pl(inputNumbersValue)

        // Устанавливаем новое значение в инпут
        setInputPhone(formattedInputValue);
        onChange(formattedInputValue);

        // Перемещение каретки к позиции ввода
        const inp = e.target
        setTimeout(() => {
            while (selectionStart < inp.value.length && [' ', '(', ')', '-'].includes(inp.value[selectionStart])) {
                selectionStart++;
            }
            selectionStart++;
            inp.setSelectionRange(selectionStart, selectionStart);
            e.preventDefault();
        }, 0);

    }

    const onPhoneKeyDown = (e) => {
        const input = e.target
        let cursorPosition = input.selectionStart || 0

        if (e.key === 'ArrowLeft' && cursorPosition <= minPosition) {
            e.preventDefault();
        }

        if (e.key === 'ArrowUp' && cursorPosition) {
            e.preventDefault();
            input.setSelectionRange(minPosition, minPosition);
        }

        // if (e.key === 'Backspace' && cursorPosition <= minPosition) {
        //     e.preventDefault();
        // }

        // Не позволяем стрелками попадать на символы ' ', '(', ')', '-'
        if (e.key === 'ArrowLeft' && cursorPosition > minPosition) {
            cursorPosition--;
            while (cursorPosition > minPosition && [' ', '(', ')', '-'].includes(input.value[cursorPosition - 1])) {
                cursorPosition--;
            }
            input.setSelectionRange(cursorPosition, cursorPosition);
            e.preventDefault();
        }

        if (e.key === 'ArrowRight') {
            while (cursorPosition < input.value.length && [' ', '(', ')', '-'].includes(input.value[cursorPosition])) {
                cursorPosition++;
            }
            cursorPosition++;
            input.setSelectionRange(cursorPosition, cursorPosition);
            e.preventDefault();
        }

        // Обработка замены цифр справа от курсора
        if (/\d/.test(e.key) && cursorPosition < input.value.length) {
            e.preventDefault();
            let newValue = input.value.slice(0, cursorPosition) + e.key + input.value.slice(cursorPosition);
            let numbersValue = getInputNumbersValue(newValue);
            let formattedValue = Pl(numbersValue);
            setInputPhone(formattedValue);
            onChange(formattedValue);

            setTimeout(() => {
                while (cursorPosition < input.value.length && [' ', '(', ')', '-'].includes(input.value[cursorPosition])) {
                    cursorPosition++;
                }
                cursorPosition++;
                input.setSelectionRange(cursorPosition, cursorPosition);
                e.preventDefault();
            }, 0);
        }

        if (e.key === 'Backspace' && cursorPosition > minPosition) {
            const prevChar = input.value[cursorPosition - 1];

            // Проверяем, что предыдущий символ - это один из символов форматирования
            if ([' ', '(', ')', '-'].includes(prevChar)) {
                // Ищем ближайшую цифру слева
                let removePosition = cursorPosition - 1;
                while (removePosition > minPosition && !/\d/.test(input.value[removePosition - 1])) {
                    removePosition--;
                }

                // Если нашли цифру слева от символа форматирования
                if (/\d/.test(input.value[removePosition - 1])) {
                    // Удаляем символ форматирования и цифру слева от него
                    let newValue = input.value.slice(0, removePosition - 1) + input.value.slice(cursorPosition);
                    let numbersValue = getInputNumbersValue(newValue);
                    let formattedValue = Pl(numbersValue);

                    setInputPhone(formattedValue);
                    onChange(formattedValue);

                    setTimeout(() => {
                        let newCursorPosition = removePosition - 1;
                        input.setSelectionRange(newCursorPosition, newCursorPosition);
                    }, 0);

                    e.preventDefault();
                }
            } else if (/\d/.test(prevChar)) {
                // Если удаляемая позиция - цифра, удаляем её стандартно
                let newValue = input.value.slice(0, cursorPosition - 1) + input.value.slice(cursorPosition);
                let numbersValue = getInputNumbersValue(newValue);
                let formattedValue = Pl(numbersValue);

                setInputPhone(formattedValue);
                onChange(formattedValue);

                setTimeout(() => {
                    let newCursorPosition = cursorPosition - 1;
                    input.setSelectionRange(newCursorPosition, newCursorPosition);
                }, 0);

                e.preventDefault();
            }
        }


        // при удалении всего номера на 'Delete' || 'Backspace' ставим курсор после +48 
        const selectionStart = input.selectionStart || 0;
        const selectionEnd = input.selectionEnd || 0;
        if ((e.key === 'Delete' || e.key === 'Backspace') && selectionStart === 0 && selectionEnd === input.value.length) {
            e.preventDefault();
            input.value = countryCode + ' ';
            setInputPhone('');
            onChange('');
            setTimeout(() => {
                input.setSelectionRange(countryCode.length + 1, countryCode.length + 1);
            }, 0);
        }
    }

    const onPhoneClick = (e) => {
        const input = e.target
        const cursorPosition = input.selectionStart || 0
        // Перемещаем каретку, если она оказалась до разрешённого ввода цифр
        if (cursorPosition < minPosition) {
            input.setSelectionRange(minPosition, minPosition)
        }

    }

    const onPhoneFocus = (e) => {
        const input = e.target

        // Если поле пустое, установим начальный формат +48
        if (!input.value) {
            input.value = countryCode + ' '
            setInputPhone('')  // Обновим состояние
            onChange('');
        }
        // Устанавливаем каретку сразу после "+48 " на минимальную позицию
        setTimeout(() => {
            input.setSelectionRange(minPosition, minPosition)
        }, 0)  // Используем setTimeout, чтобы гарантированно переместить каретку после рендера
    }

    const onPhonePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('Text');
        let inputNumbersValue = getInputNumbersValue(pastedData);

        // Проверка количества цифр и формирование корректного номера
        if (inputNumbersValue.length >= 11) {
            inputNumbersValue = inputNumbersValue.substring(inputNumbersValue.length - 11);
        }
        // Если длина вставленных данных === 10, добавляем цифру 4 в начало
        else if (inputNumbersValue.length === 10) {
            inputNumbersValue = '4' + inputNumbersValue;
        }

        // Если длина вставленных данных  === 9, добавляем цифру 48 в начало
        else if (inputNumbersValue.length <= 9) {
            inputNumbersValue = '48' + inputNumbersValue;
        }

        let formattedValue = Pl(inputNumbersValue);
        setInputPhone(formattedValue);
        onChange(formattedValue);
        // // Установка каретки после "+375 ("
        // const input = e.target as HTMLInputElement;
        // setTimeout(() => {
        //     input.setSelectionRange(minPosition, minPosition)
        // }, 0)
    }

    return { onPhoneInput, onPhoneKeyDown, onPhoneClick, onPhoneFocus, onPhonePaste }
}