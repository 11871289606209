import React from 'react';
import './Pracujemy.styles.scss';
import dotsImage from '../../assets/images/dots.png';
import contactImage from '../../assets/images/contact.svg';
import calendarImage from '../../assets/images/calendar.svg';
import workImage from '../../assets/images/work.svg';
import payImage from '../../assets/images/pay.svg';

const Pracujemy = () => {
    return (
        <div className='container'>
            <div className='pracujemy-wrapper'>
                <div className="jakPracuemyTitle">
                    <div className="jakPracuemyTitleShadow">Jak pracujemy</div>
                    <div className="jakPracuemyTitleText">Jak pracujemy</div>
                </div>

                <div className='pracujemy-container'>
                    <div className='pracujemy-item'>
                        <div className='pracujemy-item-img-wrapp'>
                            <img src={contactImage} alt="contact" />
                        </div>
                        <div className='pracujemy-item-description'>
                            Skontaktuj się z nami

                        </div>
                    </div>

                    <div className='dots-wrapp'>
                        <img className='dots' src={dotsImage} alt="dots" />
                    </div>

                    <div className='pracujemy-item'>
                        <div className='pracujemy-item-img-wrapp'>
                            <img src={calendarImage} alt="calendar" />
                        </div>
                        <div className='pracujemy-item-description'>
                            Zamów usługę online
                        </div>
                    </div>

                    <div className='dots-wrapp'>
                        <img className='dots' src={dotsImage} alt="dots" />
                    </div>

                    <div className='pracujemy-item'>
                        <div className='pracujemy-item-img-wrapp'>
                            <img src={workImage} alt="work" />
                        </div>

                        <div className='pracujemy-item-description'>
                            Przyjedziemy i wykonamy pracę
                        </div>
                    </div>

                    <div className='dots-wrapp'>
                        <img className='dots' src={dotsImage} alt="dots" />
                    </div>

                    <div className='pracujemy-item'>
                        <div className='pracujemy-item-img-wrapp'>
                            <img src={payImage} alt="pay" />
                        </div>
                        <div className='pracujemy-item-description'>
                            Opłata po wykonaniu usługi
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pracujemy;