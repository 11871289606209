import React from 'react';
import './ModalMenu.styles.scss';
import Viber from '../../assets/images/viber.svg';
import Insta from '../../assets/images/Insta.svg';
import Whatsapp from '../../assets/images/whatsapp.svg';
import FaceBook from '../../assets/images/Facebook.svg';
import AnchorLink from "react-anchor-link-smooth-scroll";

const ModalMenu = ({ closeMenu }) => {
    return (
        <div className='modal-menu'>
            <div className='container'>
                <ul className='modal-menu-wrapper'>
                    <li className="modal-menu-item">
                        <ul className='modal-menu-link'>
                            <li>
                                <   AnchorLink href="#kontakt" className='anchor-link'
                                    onClick={closeMenu}
                                >kontakt</AnchorLink>
                            </li>
                            <li>
                                {window.location.pathname === '/' &&
                                    <a href="/for-companies" className='anchor-link'>Dla firm</a>
                                }
                                {window.location.pathname === '/for-companies' &&
                                    <a href="/" className='anchor-link'>Dla osob prywatnych</a>
                                }
                            </li>

                        </ul>
                    </li>
                    <li className="modal-menu-item">
                        <button className="button" type="button">
                            {window.location.pathname === '/' &&
                                <AnchorLink href="#uslugi" onClick={closeMenu}>Zamów online</AnchorLink>
                            }
                            {window.location.pathname === '/for-companies' &&
                                <AnchorLink href="#skontaktuj"
                                    onClick={closeMenu}
                                >Skontaktuj sie</AnchorLink>
                            }
                        </button>
                    </li>
                    <li className="modal-menu-item">
                        <ul className='social-list'>
                            <li className="social-list__item">
                                <a href={"viber://chat?number=%2B48730858170"} className='social-list__link'>
                                    <img src={Viber} alt="Viber" />
                                </a>
                            </li>
                            <li className="social-list__item">
                                <a href={"https://api.whatsapp.com/send/?phone=48730858170"} target='__blank' className='social-list__link'>
                                    <img src={Whatsapp} alt="WA" />
                                </a>
                            </li>
                            <li className="social-list__item">
                                <a href='https://m.facebook.com/klining.wroclaw/' target='__blank' className='social-list__link'>
                                    <img src={FaceBook} alt="FB" />
                                </a>
                            </li>
                            <li className="social-list__item">
                                <a href='https://www.instagram.com/bialo_klining?igsh=MXZ2emIzNjhuejE5dQ==' target='__blank' className='social-list__link'>
                                    <img src={Insta} alt="IG" />
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ModalMenu;