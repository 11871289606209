import React from 'react';
import HeaderContent from '../components/HeaderContent/HeaderContent';
import { WhyWe } from '../components/WhyWe/WhyWe';
import Onas from '../components/Onas/Onas';
import Pracujemy from '../components/Pracujemy/Pracujemy';
import { RelyOnUs } from '../components/RelyOnUs/RelyOnUs';
import Header from '../components/Header/Header';
import { Opinion } from '../components/Opinion/Opinion';
import { SubmissionForm } from '../components/Form/Form';
import { ScrollProvider } from '../hooks/SmoothScrolling/ScrollContext';
import Footer from '../components/Footer/Footer';
import { ScrollSection } from '../hooks/SmoothScrolling/ScrollSection';

export const HomePages = () => {

    return (
        // <>
        //     <Header />
        //     <ScrollProvider>
        //         <ScrollSection index={0}>
        //             <HeaderContent />
        //         </ScrollSection>
        //         <ScrollSection index={1}>
        //             <WhyWe />
        //         </ScrollSection>
        //         <ScrollSection index={2}>
        //             <Onas />
        //         </ScrollSection>
        //         <SubmissionForm />
        //         <ScrollSection index={10}>
        //             <Pracujemy />
        //         </ScrollSection>
        //         <ScrollSection index={11}>
        //             <RelyOnUs />
        //         </ScrollSection>
        //         <ScrollSection index={12}>
        //             <Opinion />
        //         </ScrollSection>
        //         <ScrollSection index={13}>
        //             <Footer />
        //         </ScrollSection>
        //     </ScrollProvider >
        // </>
        <>
            <Header />
            <HeaderContent />
            <WhyWe />
            <Onas />
            <SubmissionForm />
            <Pracujemy />
            <RelyOnUs />
            <Opinion />
        </>
    );
}




