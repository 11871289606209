import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import pl from "date-fns/locale/pl";
import { useEffect } from "react";
import { getOrderDate } from "../../helpers/Api";
import 'react-datepicker/dist/react-datepicker.css';
import '../Datepicker/Datepicker.scss';

export function Datepicker({ onDateChange, selectedDate, setSelectedDate, }) {

  const today = new Date();
  const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2); // заказать услугу можно не ранее, чем через три дня
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate()); // услугу можно заказать на три месяца вперёд
  const excludedHours = [0, 1, 2, 3, 4, 5, 6, 7, 9, 11, 13, 15, 17, 18, 19, 20, 21, 22, 23];
  const excludeTimes = excludedHours.map(hour => new Date(0, 0, 0, hour, 0));

  const [reservedTimes, setReservedTimes] = useState([]);

  useEffect(() => {
    getOrderDate().then((data) => {
      const groupedData = data.reduce((acc, { date, date_time, order_id, id }) => {
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push({ id: id, time: date_time, disabled: order_id === 0 });
        return acc;
      }, {});

      const disabledTimes = Object.entries(groupedData).map(([date, times]) => {
        const countMap = times.reduce((count, { time }) => {
          count[time] = (count[time] || 0) + 1;
          return count;
        }, {});

        return times.map(({ time, disabled, id }) => ({
          date,
          time,
          disabled: countMap[time] > 1 || disabled,
          id,
        }));
      });

      setReservedTimes(disabledTimes);
    });
  }, []);

  const excludedTimes = reservedTimes.reduce((acc, times) => {
    times.forEach((time) => {
      const date1 = new Date(selectedDate).toLocaleString().slice(0, 10)
      const date2 = new Date(time.date).toLocaleString().slice(0, 10)
      if (selectedDate && date1 === date2 && time.disabled) {
        const hour = parseInt(time.time.split(":")[0]);
        acc.push(new Date(0, 0, 0, hour, 0));
      }
    });
    return acc;
  }, [...excludeTimes]);


  const handleDateTimeChange = (date) => {
    if (!selectedDate) {
      // Если даты нет, просто устанавливаем её с нулевым временем
      const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setSelectedDate(newDate); // Сбрасываем выбранное время
      onDateChange(newDate);
    } else {
      // Если дата уже выбрана, определяем, изменилось ли время или дата
      const isDateChanged =
        selectedDate.getFullYear() !== date.getFullYear() ||
        selectedDate.getMonth() !== date.getMonth() ||
        selectedDate.getDate() !== date.getDate();

      if (isDateChanged) {
        // Дата изменилась: сбрасываем время
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        setSelectedDate(newDate);
        onDateChange(newDate);
      } else {
        // Время изменилось: обновляем дату с новым временем
        const updatedDate = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          date.getHours(),
          date.getMinutes()
        );
        setSelectedDate(updatedDate);
      }
    }
  };


  const timeClassName = (time) => {
    const isBookedTime = excludedTimes.some(excludedTime =>
      time.getHours() === excludedTime.getHours() &&
      time.getMinutes() === excludedTime.getMinutes()
    );
    if (!selectedDate) {
      return "all-disabled-time";
    }
    return isBookedTime ? 'disabled-time' : 'undisabled-time';

  }

  const dateClassName = (date) => {
    const dateString = date.toLocaleDateString('ru');
    
    const allTimesDisabled = reservedTimes.some(times => {
      const formattedDate = new Date(times[0].date).toLocaleDateString('ru'); // Берём первую дату из массива
      return formattedDate === dateString && times.every(time => time.disabled); // Проверяем, что все времена этой даты забронированы
    });
  
    return allTimesDisabled ? 'disabled-date' : '';
    
  };

  return (
    <div className="block-form__form__left__termin">
      <div className="block-form__form__left__osobiste">
        <h5>Wybierz termin <span>(wymagane)</span></h5>
        <div className="data-czas">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateTimeChange} // Меняет только дату
            // onSelect={handleTimeChange} // Меняет только время
            dateFormat="dd.MM.yyyy"
            minDate={minDate}
            locale={pl}
            timeCaption="godzina"
            showTimeSelect
            inline
            shouldCloseOnSelect={false}
            timeIntervals={60}
            excludeTimes={excludeTimes}
            timeClassName={timeClassName}
            dayClassName={dateClassName}
            disabled={!selectedDate}
            maxDate={maxDate}

          // excludeTimes={excludedTimes}
          // timeClassName={() => {
          //     if (!selectedDate) {
          //         return "disabled-time";
          //     }
          // }}
          />
        </div>
      </div>
    </div>

  );
};