import React, { useEffect } from "react";
import { useMask } from "../../../hooks/phone-mask/use-mask";
import { Input } from "../../../shared/input/Input";
import { postFindUser } from "../../helpers/Api";
import './ClientData.scss';

export function ClientData({
  inputPhone,
  setInputPhone,
  formik,
  isFocused,
  setIsFocused, 
  setDiscountPhone
}) {
  // const { firstName, lastName, yourAddress, phone, email, pocztowy, informacja } = props;

  // const mask = (event) => {
  //   let keyCode;
  //   event.keyCode && (keyCode = event.keyCode);
  //   let pos = event.target.selectionStart;
  //   if (pos < 4) event.preventDefault();
  //   let matrix = "+48 ___ ___ ___",
  //     i = 0,
  //     def = matrix.replace(/\D/g, ""),
  //     val = event.target.value.replace(/\D/g, ""),
  //     new_value = matrix.replace(/[_\d]/g, function (a) {
  //       return i < val.length ? val.charAt(i++) || def.charAt(i) : a
  //     });
  //   i = new_value.indexOf("_");
  //   if (i !== -1) {
  //     i < 5 && (i = 4);
  //     new_value = new_value.slice(0, i);
  //   }

  //   let reg = matrix.substr(0, event.target.value.length).replace(/_+/g,
  //     function (a) {
  //       return "\\d{1," + a.length + "}"
  //     }).replace(/[+()]/g, "\\$&");
  //   reg = new RegExp("^" + reg + "$");
  //   if (!reg.test(event.target.value) || event.target.value.length < 5 || (keyCode > 47 && keyCode < 58)) {
  //     event.target.value = new_value;
  //     // Adjust cursor position if it ends up in a space
  //     while (event.target.value.charAt(pos) === ' ' && pos < new_value.length) {
  //       pos++;
  //     }

  //     event.target.selectionStart = event.target.selectionEnd = pos;
  //   }
  //   if (event.type === "blur" && event.target.value.length < 3) event.target.value = "";
  // }


  // const [error, setError] = useState(false);
  // const [typingTimeout, setTypingTimeout] = useState(null);

  // const handlePhoneChange = (event) => {
  //   const value = event.target.value;
  //   props.setField('phone', value);

  //   if (typingTimeout) {
  //     clearTimeout(typingTimeout);
  //   }

  //   setTypingTimeout(setTimeout(() => {
  //     setError(value.length !== 15);
  //   }, 600)); // задержка в 1 секунду
  // };

  const country = 'PL';
  const inputCode = '+48 ';
  const handlePhoneChange = (value) => {
    formik.setFieldValue('phone', value.length !== 0 ? inputCode + value : '');
  };
  const { onPhoneInput, onPhoneKeyDown, onPhoneClick, onPhoneFocus, onPhonePaste } = useMask(
    country,
    inputCode,
    setInputPhone,
    handlePhoneChange);

  useEffect(() => {
    if (formik.values.phone.length === 15) {
      postFindUser({
        phone: formik.values.phone
      }).then((res) => {
        setDiscountPhone(res.data.discount);
      })
        .catch(e => {
          if (e.response.status === 404 || e.response.status === 400) {
            setDiscountPhone(0)
            return
          }
        })
    } else if (formik.values.phone.length < 15) {
      setDiscountPhone(0)
    }
  }, [formik.values.phone, setDiscountPhone]);

  return (
    <div className="block-form__form__left__client">
      <div className="block-form__form__left__osobiste">
        <h5>Dane osobiste</h5>
        <div className="client-data-wrapp">
          <label className="client-data-label">
            <div>Imię <span>(wymagane)</span></div>
            <Input
              name="firstname"
              id="firstname_form"
              type='text'
              className={formik.touched.firstname && formik.errors.firstname ? 'error-input' : ''}
              placeHolder='Imię'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstname}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <span className="error-message">{formik.errors.firstname}</span>
            ) : null}
          </label>
          <label className="client-data-label">
            <div>Nazwisko <span>(opcjonalnie)</span></div>
            <Input
              name="lastname"
              type='text'
              className={formik.touched.lastname && formik.errors.lastname ? 'error-input' : ''}
              placeHolder='Nazwisko'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastname}
            />
          </label>
        </div>
        <div className="client-data-wrapp">
          <label className="client-data-label">
            <div>Email <span>(wymagane)</span></div>
            <Input
              name="email"
              type='email'
              placeHolder='me@contakt.pl'
              className={formik.touched.email && formik.errors.email ? 'error-input' : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <span className="error-message">{formik.errors.email}</span>
            ) : null}
          </label>
          <label className="client-data-label">
            <div>Telefon <span>(wymagane)</span></div>
            {/* <input type="tel" name='phone'
              value={phone}
              // onChange={event => props.setField('phone', event.target.value)}
              onChange={handlePhoneChange}
              placeholder="+48 ___ ___ ___"
              onInput={mask}
              onFocus={mask}
              onBlur={mask}
              onKeyDown={mask}
            /> */}
            {/* <span className="error-message">{error ? 'Numer telefonu jest nieprawidłowy' : ''}</span> */}
            <Input
              name="phone"
              type='tel'
              className={formik.touched.phone && formik.errors.phone ? 'error-input' : ''}
              placeHolder='+48 ___ ___ ___'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={isFocused ? inputCode + inputPhone : ''}
              maxLength={15}
              onInput={onPhoneInput}
              onKeyDown={onPhoneKeyDown}
              onPaste={onPhonePaste}
              onClick={onPhoneClick}
              onFocus={(e) => { setIsFocused(true); onPhoneFocus(e) }}
              autoComplete="off-phone"
            />
            {formik.touched.phone && formik.errors.phone ? (
              <span className="error-message">{formik.errors.phone}</span>
            ) : null}
          </label>
        </div>
        <div className="client-data-wrapp-address">
          <label className="client-data-label address">
            <div>Adres <span>(wymagane)</span></div>
            <Input
              name="adress"
              type='text'
              placeHolder='Ulica, nr. Domu i Mieszkania'
              className={formik.touched.adress && formik.errors.adress ? 'error-input' : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.adress}
            />
            {formik.touched.adress && formik.errors.adress ? (
              <span className="error-message">{formik.errors.adress}</span>
            ) : null}
          </label>
          <label className="client-data-label code">
            <div>Kod pocztowy <span>(opcjonalnie)</span></div>
           
            <Input
              name="kod_index"
              type='text'
              placeHolder='__-___'
              className={formik.touched.kod_index && formik.errors.kod_index ? 'error-input' : ''}
              onChange={(e) => {
                const filteredValue = e.target.value.replace(/[^0-9-]/g, ''); // Фильтрация: оставляем только цифры и "-"
                formik.setFieldValue('kod_index', filteredValue);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.kod_index}
            />
          </label>
        </div>
        <label className="client-data-label">
          <div>Dodatkowe pole informacji <span>(opcjonalnie)</span></div>
          {/* <textarea name="notes"
            value={informacja}
            onChange={event => props.setField('informacja', event.target.value)}
            placeholder="Text" /> */}
          <textarea
            name="notes"
            id="form-notes"
            placeholder='Tekst'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.notes}
          />
        </label>
      </div>
    </div>
  )
}