export const Pl = (inputNumbersValue) => {

    let formattedValue = ''

    if (inputNumbersValue.length > 2) {
        formattedValue += "" + inputNumbersValue.substring(2, 5)
    }
    if (inputNumbersValue.length > 5) {
        formattedValue += " " + inputNumbersValue.substring(5, 8)
    }
    if (inputNumbersValue.length > 8) {
        formattedValue += " " + inputNumbersValue.substring(8, 11)
    }
    

    return formattedValue
}