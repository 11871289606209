import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as File } from '../../assets/images/add_file.svg';
import "./FileInput.scss";
import { Input } from '../../shared/input/Input';

// export const FileInput = ({ file, setFile, name }) => {
//     // const [inputKey, setInputKey] = useState(Date.now())
//     const inputRef = useRef(null);

//     const handleFileChange = (event) => {
//         const files = event.target.files;
//         console.log(files);
//         if (files.length > 0) {
//             const fileName = files[0].name;
//             const extension = fileName.split('.').pop();
//             const maxLength = 32;
//             const truncatedName = fileName.slice(0, maxLength - extension.length - 1);
//             if (fileName.length <= maxLength) {
//                 setFile(fileName);
//             } else {
//                 setFile(`${truncatedName}...${extension}`);
//             }
//         } else {
//             setFile('');
//         }
//     };

//     const handleFileDelete = () => {
//         setFile('');
//         // setInputKey(Date.now())
//         const dataTransfer = new DataTransfer();
//         if (inputRef.current) {
//             inputRef.current.files = dataTransfer.files;
//         }
//     };

//     const labelRef = useRef(null);

//     // const handleFocus = () => {
//     //     if (labelRef.current) {
//     //       labelRef.current.focus();
//     //     }
//     //   };
//     useEffect(() => {
//         const handleKeyDown = (event) => {
//             if (event.key === 'Enter' && labelRef.current) {
//                 // Если нажат Enter, фокусируем input
//                 inputRef.current && inputRef.current.click();
//             }
//         };
//         // Добавляем слушатель только к label
//         labelRef.current && labelRef.current.addEventListener('keydown', handleKeyDown);

//         return () => {
//             labelRef.current && labelRef.current.removeEventListener('keydown', handleKeyDown);
//         };
//     }, []);

//     return (
//         <div className="input__wrapper">
//             <Input
//                 // key={inputKey}
//                 name={name}
//                 type="file"
//                 id="input__file-client"
//                 className="input input__file"
//                 onChange={handleFileChange}
//                 inputRef={inputRef}
//                 tabIndex={-1}
//             />
//             <label
//                 tabIndex={0}
//                 ref={labelRef}
//                 htmlFor="input__file-client"
//                 className="input__file-button"
//             >
//                 <span className="input__file-icon-wrapper">
//                     <File />

//                 </span>
//                 {file ? (
//                     <span className="input__file-button-text">{file}</span>
//                 ) : (
//                     <span className="input__file-button-text">dodaj załącznik</span>
//                 )}
//             </label>
//             {file &&
//                 <button className='input__file-button-delete' onClick={handleFileDelete}>x</button>
//             }
//         </div>
//     );
// }


export const FileInput = ({ file, setFile, name, setFileSize, errorMes, setErrorMes }) => {
    const [inputKey, setInputKey] = useState(Date.now());
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const uploadedFile = files[0]; // Сам файл
            const fileName = uploadedFile.name; // Имя файла
            const extension = fileName.split('.').pop();
            const maxLength = 32;
            const truncatedName = fileName.slice(0, maxLength - extension.length - 1);

            setFile(uploadedFile); // Сохраняем сам файл в состоянии
            const fileSize = files[0].size;
            setFileSize(fileSize);
            if (fileName.length <= maxLength) {
                setFileName(fileName);
            } else {
                setFileName(`${truncatedName}...${extension}`);
            }
        } else {
            setFile(null); // Удаляем файл, если ничего не выбрано
            setFileName('');
            setFileSize(0);
        }
    };

    const handleFileDelete = () => {
        setFile(null);
        setFileName('');
        setErrorMes('');
        setFileSize(0);
        setInputKey(Date.now()); // Сбрасываем input для повторного выбора файла
    };

    return (
        <div className="input__wrapper">
            <input
                key={inputKey}
                name={name}
                type="file"
                id="input__file"
                className="input input__file"
                onChange={handleFileChange}
            />
            <label htmlFor="input__file" className="input__file-button">
                <span className="input__file-icon-wrapper">
                    <File />
                </span>
                {fileName ? (
                    <span className="input__file-button-text">{fileName}</span>
                ) : (
                    <span className="input__file-button-text">Dodaj załącznik</span>
                )}
            </label>
            {fileName && (
                <button className="input__file-button-delete" onClick={handleFileDelete}>
                    x
                </button>
            )}
            {/* {errorMes &&
                <div className="error-message">{errorMes}</div>
            } */}
        </div>
    );
};
