import Footer from "../components/Footer/Footer"
import { FormForCompanies } from "../components/FormForCompanies/FormForCompanies"
import { HeaderCompanies } from "../components/Header/HeaderCompanies"
import { Reference } from "../components/Reference/Reference"
import { UslugiTab } from "../components/UslugiTab/UslugiTab"
import { ScrollProvider } from "../hooks/SmoothScrolling/ScrollContext"
import { ScrollSection } from "../hooks/SmoothScrolling/ScrollSection"

export const ForCompanies = () => {
  return (
    // <>
    //   <ScrollProvider>
    //     <ScrollSection index={0}>
    //       <HeaderCompanies />
    //     </ScrollSection>

    //     <ScrollSection index={1}>
    //       <UslugiTab />
    //     </ScrollSection>

    //     <ScrollSection index={2}>
    //       <FormForCompanies />
    //     </ScrollSection>

    //     <ScrollSection index={3}>
    //       <Reference />
    //     </ScrollSection>

    //     <ScrollSection index={4}>
    //       <Footer />
    //     </ScrollSection>
    //   </ScrollProvider>
    // </>
    <>
      <HeaderCompanies />
      <UslugiTab />
      <FormForCompanies />
      <Reference />
    </>
  )
}