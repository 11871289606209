import { useState } from 'react';

export const useFetch = (initialStatus = 0) => {
    const [loading, setLoading] = useState(false);
    const [statusForm, setStatusForm] = useState(initialStatus);
    const [errorMesForm, setErrorMesForm] = useState('');
    const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);
    const [isOpenModalError, setIsOpenModalError] = useState(false);
    const [isOpenModalErrorDate, setIsOpenModalErrorDate]  = useState(false);

    const fetchClient = async (url, method, body, resetForm) => {
        setLoading(true);
        try {
            const response = await fetch(url, {
                method,
                body,
            });

            if (response.ok) {
                console.log('Success');
                setTimeout(() => {
                    resetForm(); // метод resetForm() предоставляет библиотека Formik
                    setLoading(false);
                    setStatusForm(2);
                    setIsOpenModalSuccess(true);
                }, 600);
            } else if(response.status === 403) {
                const errorText = await response.text();
                console.error('Error:', errorText);
                setTimeout(() => {
                    setLoading(false);
                    // setStatusForm(4);
                    // setErrorMesForm(errorText);
                    setStatusForm(4);
                    setIsOpenModalErrorDate(true);
                }, 600);
            } else {
                const errorText = await response.text();
                console.error('Error:', errorText);
                setTimeout(() => {
                    setLoading(false);
                    // setStatusForm(4);
                    // setErrorMesForm(errorText);
                    setStatusForm(3);
                    setIsOpenModalError(true);
                }, 600);
            }
        } catch (error) {
            console.error('Error:', error);
            setTimeout(() => {
                setLoading(false);
                setStatusForm(3);
                setIsOpenModalError(true);
            }, 600);
        }
    };

    return {
        loading,
        statusForm,
        setStatusForm,
        errorMesForm,
        isOpenModalSuccess,
        isOpenModalError,
        isOpenModalErrorDate,
        fetchClient,
        setIsOpenModalSuccess,
        setIsOpenModalError,
        setIsOpenModalErrorDate,
    };
};
