import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useFetch } from '../../hooks/useFetchClient';

// Регулярное выражение для валидации формата телефона +48 xxx xxx xxx
const phoneRegExp = /^\+48 \d{3} \d{3} \d{3}$/;

export const useContactFormClient = () => {
    const [inputPhone, setInputPhone] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [file, setFile] = useState(null);
    const [valueText, setValueText] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [errorMes, setErrorMes] = useState('');
    const [fileSize, setFileSize] = useState(0);

    const {
        loading,
        statusForm,
        setStatusForm,
        errorMesForm,
        isOpenModalSuccess,
        isOpenModalError,
        // isOpenModalErrorDate,
        fetchClient,
        setIsOpenModalSuccess,
        setIsOpenModalError,
        // setIsOpenModalErrorDate
    } = useFetch();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
        },
        validationSchema: yup.object({
            name: yup.string()
                .required('Wypełnij pole'),
            email:
                yup.string().email('Adres e-mail jest nieprawidłowy')
                    .required('Wypełnij pole'),
            phone: yup.string()
                .matches(phoneRegExp, 'Numer telefonu jest nieprawidłowy')
                .required('Wypełnij pole'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const MAX_FILE_SIZE_MB = 25; // Максимальный размер файла в МБ
            const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

            if (file &&  fileSize > MAX_FILE_SIZE_BYTES) {
                setErrorMes('Wielkość pliku nie może przekraczać 25 MB!')
                return
            }

            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('phone', values.phone);
            formData.append('text', valueText);

            if (file) {
                formData.append('attachment', file);
            }


            const formReset = () => {
                setIsFocused(false);
                setInputPhone('');
                setValueText('');
                setFile(null);
                setErrorMes('');
                setFileSize(0);
                setIsChecked(false);
                resetForm();
            }

            await fetchClient('/api/feedback', 'POST', formData, formReset);
        },
    });

    return {
        formik,
        inputPhone,
        setInputPhone,
        valueText,
        setValueText,
        isChecked,
        setIsChecked,
        file,
        setFile,
        errorMes, 
        setErrorMes,
        fileSize, 
        setFileSize,
        isFocused,
        setIsFocused,
        loading,
        statusForm,
        setStatusForm,
        errorMesForm,
        isOpenModalSuccess,
        isOpenModalError,
        setIsOpenModalSuccess,
        setIsOpenModalError,

    };
};