import React from 'react';
import topImage from '../../assets/images/top-image.png';
import placeMarkImg from '../../assets/images/placemark.png';
import AnchorLink from "react-anchor-link-smooth-scroll";
import './HeaderContent.styles.scss';

const HeaderContent = () => {

    return (
        <div className='header-container'>
            <div className='container'>
                <div className='header-content-wrapper'>
                    <div className='left-side'>
                        <div className='left-side-title'>Firma sprzątająca</div>
                        <div className='place-wrapper'>
                            <div className='place-title'>
                                <img src={placeMarkImg} alt="placemarkIco" />
                                <span>działająca na terenie Wrocławia</span>
                            </div>
                        </div>
                        <div className="left-side-description">
                            <span> Posprzątamy delikatnie,
                                uwolnimy Cię od śmieci i bałaganu.</span>
                            <span>Wyczyścimy meble tapicerowane i wykładziny.</span>
                        </div>
                        <button className="button" type="button">
                            <AnchorLink href="#uslugi">Zamów online</AnchorLink>
                        </button>
                    </div>
                    <div className='right-side'>
                        <img src={topImage} alt="topimg" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderContent;