import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AdditionalServices } from './AdditionalServices/AdditionalServices';
import { PremisesCleaning } from './PremisesCleaning/PremisesCleaning';
import { WindowCleaning } from './WindowCleaning/WindowCleaning';
import { FurnitureCleaning } from './FurnitureCleaning/FurnitureCleaning';
import { SelectCity } from './SelectCity/SelectCity';
import { ClientData } from './ClientData/ClientData';
import { Datepicker } from './Datepicker/Datepicker';
import { postFindUser, postOrder, postPromocodeGet } from '../helpers/Api';
import { ModalReglament } from '../Modal/ModalReglament';
import { ModalPrivacyPolicy } from '../Modal/ModalPrivacyPolicy';
import { Modal } from '../Modal/Modal';
import { ScrollSection } from '../../hooks/SmoothScrolling/ScrollSection';
import './Form.style.scss';
import { useContactForm } from './hooks';
import { Loader } from '../../shared/loader/loader';
import { Input } from '../../shared/input/Input';


export const SubmissionForm = () => {
  const [miasto, setMiasto] = useState([]);
  const [premisesCleaning, setPremisesCleaning] = useState([]);
  const [dodatkowe, setDodatkowe] = useState([]);
  const [windows, setWindows] = useState([]);
  const [furniture, setFurniture] = useState([]);
  const [valuePromocode, setValuePromocode] = useState('');
  const [promocodeId, setPromocodeId] = useState(0);
  const [status, setStatus] = useState('');

  const {
    formik,
    inputPhone,
    setInputPhone,
    isFocused,
    setIsFocused,
    loading,
    statusForm,
    setStatusForm,
    errorMesForm,
    isOpenModalSuccess,
    isOpenModalError,
    isOpenModalErrorDate,
    setIsOpenModalSuccess,
    setIsOpenModalError,
    setIsOpenModalErrorDate,
    selectedMiasto,
    setSelectedMiasto,
    priceFurniture,
    setPriceFurniture,
    priceOkno,
    setPriceOkno,
    filteredCheckboxes,
    checkboxes,
    setCheckboxes,
    selectedDate,
    setSelectedDate,
    discount,
    setDiscount,
    discountPhone,
    setDiscountPhone,
    priceSumm,
    baseTotal,
    changeUslugiFirstBlock,
    setChangeUslugiFirstBlock,
    priceOne,
    setPriceOne,
    mamyChasy,
    summFirst,
    summSecond,
    summThird,
    summDodat,
  } = useContactForm(dodatkowe, windows, furniture, promocodeId, premisesCleaning);


  // const baseTotal = summFirst() + summDodat() + summSecond() + summThird();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  // const filteredCheckboxes = {};

  for (const key in checkboxes) {
    if (checkboxes[key]) {
      filteredCheckboxes[key] = checkboxes[key] ? 1 : 0;
    }
  }

  const dataMain = [];

  for (let i = 0; i <= 30; i++) {
    dataMain.push(i);
  }

  let mailPaslugi = () => {
    let mailText = '';

    if (!changeUslugiFirstBlock) {
      mailText += `Sprzątanie standardowe;<br />`;
    } else {
      mailText += `Sprzątanie gruntowne (po wynajmie);<br />`;
    }

    if (priceOne && priceOne !== '0') {
      mailText += `powierzchnia: ${priceOne} m2<br />`;
    }

    dodatkowe.forEach(option => {
      if (checkboxes[option.id]) {
        mailText += `${option.name}<br />`;
      }
    });

    windows.forEach((option) => {
      const value = priceOkno[option.id] || 0;
      if (value !== 0) {
        mailText += `${option.name}: ${value} szt<br />`
      }
    });

    furniture.forEach((option) => {
      const value = priceFurniture[option.id] || 0;
      if (value !== 0) {
        if (option.name.toLowerCase().includes('wykładzina')) {
          mailText += `<div> ${option.name}: ${value} m2</div>`;
        } else {
          mailText += `<div> ${option.name}: ${value} szt</div>`;
        }
      }
    });

    return (
      mailText
    )
  }

  let paslugiForm = () => {
    let mainText = '';

    if (priceOne !== '') {
      if (!changeUslugiFirstBlock) {
        mainText += `<div>Sprzątanie standardowe</div>`;
      } else {
        mainText += `<div>Sprzątanie gruntowne (po wynajmie)</div>`;
      }
    }

    if (priceOne && priceOne !== '0') {
      mainText += `<div>powierzchnia: ${priceOne} m2<div>`;
    }

    furniture.forEach((option) => {
      const value = priceFurniture[option.id] || 0;
      if (value !== 0) {
        if (option.name.toLowerCase().includes('wykładzina')) {
          mainText += `<div> ${option.name}: ${value} m2</div>`;
        } else {
          mainText += `<div> ${option.name}: ${value} szt</div>`;
        }
      }
    });

    windows.forEach((option) => {
      const value = priceOkno[option.id] || 0;
      if (value !== 0) {
        mainText += `<div> ${option.name}: ${value} szt</div>`
      }
    });

    if (priceOne !== '') {
      dodatkowe.forEach(option => {
        if (checkboxes[option.id]) {
          mainText += `<div>${option.name}</div>`;
        }
      });
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: mainText }} />
    )
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeAgree = () => {
    setIsChecked(!isChecked);
  };

  const [isCheckedVat, setIsCheckedVat] = useState(false);

  const handleCheckboxChangeVat = () => {
    setIsCheckedVat(!isCheckedVat);
  };


  const validateForm = () => {
    if (baseTotal >= 120 && isChecked && selectedDate !== null && new Date(selectedDate).toLocaleTimeString('pl') !== '00:00:00' &&
      formik.values.email !== ''
      &&
      formik.values.phone !== '' && formik.values.phone.length === 15 && formik.values.firstname !== '' && formik.values.adress !== '') {
      return 1;
    } else {
      return 0;
    }
  }

  const formMess = () => {
    let formMessVar = '';
    if (validateForm()) {
      formMessVar = 'mogę wysłać';
    } else {
      formMessVar = 'wypełnij pola';
    }
    return formMessVar;
  }

  const handleCloseModalSuccess = () => {
      setIsOpenModalSuccess(false)
      setIsCheckedVat(false)
      setIsChecked(false)
      setStatus(0)
      window.location.reload();
  }

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const [isOpenModalPrivacy, setIsOpenModalPrivacy] = useState(false);
  const handleOpenModalPrivacy = () => setIsOpenModalPrivacy(true);
  const handleCloseModalPrivacy = () => setIsOpenModalPrivacy(false);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };


  // const [discountPhone, setDiscountPhone] = useState(0);

  const handleButtonPromocodeClick = () => {
    postPromocodeGet({
      name: valuePromocode
    }).then((res) => {
      setDiscount(res.data.discount);
      // setPromocodeName(res.data.name)
      setPromocodeId(res.data.id);
      setStatus(1)
    })
      .catch(e => {
        if (e.response.status === 404 || e.response.status === 400) {
          setStatus(2);
          setDiscount(0)
          return
        }
      })
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [resultStyle, setResultStyle] = useState({});

  const uslugiRef = useRef(null);
  const resultRef = useRef(null);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useLayoutEffect(() => {
    const handleScrollPosition = () => {
      if (window.innerWidth <= 820) {

        // const resultTop = window.innerWidth === 820 ? '78' : window.innerWidth >= 768 ? '76' : window.innerWidth >= 540 ? '75' : window.innerWidth >= 360 ? '80' : '78';
        // const unit = 'vh';
        const resultMinusTop = window.innerWidth === 820 ? 900 : window.innerWidth >= 768 ? 800 : window.innerWidth >= 540 ? 600 : window.innerWidth >= 360 ? 650 : 600;
        if (uslugiRef.current && resultRef.current) {
          const uslugiPosition = uslugiRef.current.getBoundingClientRect().top + window.scrollY;
          const resultPosition = resultRef.current.getBoundingClientRect().top + window.scrollY - resultMinusTop;

          if (scrollPosition >= uslugiPosition && scrollPosition <= resultPosition) {
            setResultStyle({ display: 'flex' });
          } else {
            setResultStyle({ display: 'none' });
          }
        }
      }
    };

    handleScrollPosition();

    window.addEventListener('scroll', handleScrollPosition);

    return () => {
      window.removeEventListener('scroll', handleScrollPosition);
    };
  }, [scrollPosition]);

  const handleScrollToResult = () => {
    const resultElement = document.getElementById('result');
    if (resultElement) {
      resultElement.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <>
      {/* <ScrollSection index={3}></ScrollSection> */}
      <div className="block-form" id='uslugi'
        ref={uslugiRef}
      >
        {loading && <Loader loading={loading} />}
        <div className="container">

          <div className="block-form__h2">
            <h2>Złożyc zamówienie</h2>
          </div>
          <div className="block-form__form">

            <div className="block-form__form__left" id='uslugi-form'>
              <SelectCity
                miasto={miasto}
                setMiasto={setMiasto}
                selectedMiasto={selectedMiasto}
                setSelectedMiasto={setSelectedMiasto}
              />

              <div className="block-form__form__left__osobiste">
                <h5>Wybór usług <span>(wymagane)</span></h5>
                {/* <ScrollSection index={4}> */}
                <PremisesCleaning
                  premisesCleaning={premisesCleaning}
                  setPremisesCleaning={setPremisesCleaning}
                  priceOne={priceOne}
                  setPriceOne={setPriceOne}
                  changeUslugiFirstBlock={changeUslugiFirstBlock}
                  setChangeUslugiFirstBlock={setChangeUslugiFirstBlock}
                />
                {/* </ScrollSection > */}
                {/* <ScrollSection index={5}> */}
                {priceOne !== '' &&
                  <AdditionalServices
                    dodatkowe={dodatkowe}
                    setDodatkowe={setDodatkowe}
                    checkboxes={checkboxes}
                    setCheckboxes={setCheckboxes}
                  />
                }

                {/* </ScrollSection > */}
                {/* <ScrollSection index={6}> */}
                <WindowCleaning
                  windows={windows}
                  setWindows={setWindows}
                  setPriceOkno={setPriceOkno}
                  priceOkno={priceOkno}
                />
                {/* </ScrollSection > */}
                {/* <ScrollSection index={7}> */}
                <FurnitureCleaning
                  furniture={furniture}
                  setFurniture={setFurniture}
                  priceFurniture={priceFurniture}
                  setPriceFurniture={setPriceFurniture}
                />
                {/* </ScrollSection> */}
              </div>
              {/* <ScrollSection index={8}> */}
              <div className='block-form__form__left__wrapper-date'>
                <Datepicker onDateChange={handleDateChange}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
                {/* <ClientData {...fields} setField={setField} /> */}
                <ClientData
                  inputPhone={inputPhone}
                  setInputPhone={setInputPhone}
                  formik={formik}
                  isFocused={isFocused}
                  setIsFocused={setIsFocused}
                  setDiscountPhone={setDiscountPhone}
                />
              </div>
              {/* </ScrollSection> */}
            </div>

            <div className='block-price-summ-wrapp' ref={resultRef}>
              <div className='block-price-summ' style={resultStyle} onClick={handleScrollToResult}>
                {/* <span className='podsumowane'>Podsumowanie:</span>
                <span className='price-summ'>{Math.round(summFirst() + summDodat() + summSecond() + summThird())} pl</span> */}
                {(discountPhone === 0 || discountPhone === null) && discount === 0 ? (
                      <div className='form-wrapp-item-price'>
                        <div className='podsumowane'>Podsumowanie:</div>
                        <div className="price-summ">{Math.round(priceSumm())} pl</div>
                      </div>
                    ) : (
                      <>
                        <div className='form-wrapp-item-price'>
                          <div className='podsumowane'>Podsumowanie:</div>
                          <div className="price-summ price-summ-old">{Math.round(summFirst() + summDodat() + summSecond() + summThird())} pl</div>
                          <div className="price-summ">{Math.round(priceSumm())} pl</div>
                        </div>
                      </>
                    )}
              </div>
            </div>
            <div
              className="block-form__form__result"
              id="result"
            >
              <div className="block-form__form__result__block"
                id="form-result"
              >
                {/* <h5>Podsumowanie:</h5> */}
                <form name="finish" className="__result__block__form" onSubmit={formik.handleSubmit}>
                  <div className="__result__block__form__item">
                    {/* <div className='form-item-price-ml'> */}
                    {/* <div>Cena:</div> */}

                    {(discountPhone === 0 || discountPhone === null) && discount === 0 ? (
                      <div className='form-wrapp-item-price'>
                        <div className='__item__content-title'>Podsumowanie:</div>
                        <div className="__item__content form-item-price">{Math.round(priceSumm())} pl</div>
                      </div>
                    ) : (
                      <>
                        <div className='form-wrapp-item-price'>
                          <div className='__item__content-title'>Podsumowanie:</div>
                          {/* <div className='form-price-old-wrapp'> */}
                          <div className="__item__content form-item-price form-item-price-old">{Math.round(summFirst() + summDodat() + summSecond() + summThird())} pl</div>
                          <div className="__item__content form-item-price">{Math.round(priceSumm())} pl</div>
                        </div>
                        {/* </div> */}
                        {discount > 0 && <div className='discount-text'>Zniżka za kod promocyjny <span className='discount'>{discount}%</span></div>}
                        {discountPhone > 0 && <div className='discount-text'>Indywidualna zniżka <span className='discount'>{discountPhone}%</span></div>}
                      </>
                    )}
                    <input type="hidden" name="total" value={Math.round(priceSumm())} />
                  </div>
                  {/* </div> */}
                  <div className="__result__block__form__item">
                    <div>Miasto:</div>
                    <div className="__item__content">{selectedMiasto.name}</div>
                  </div>
                  <div className="__result__block__form__item">
                    <div>Usługi:</div>
                    <div className="__item__content form-item-services">
                      {paslugiForm()}
                    </div>
                    {/* {<input type="hidden" name="order_info" value={mailPaslugi()} />} */}
                  </div>
                  <div className="__result__block__form__item">
                    <div>Data:</div>
                    <div className="__item__content">{selectedDate ? new Date(selectedDate).toLocaleDateString('pl', options) : 'nie wybrano'}</div>

                  </div>
                  <div className="__result__block__form__item">
                    <div>Сzas:</div>
                    <div className="__item__content">{selectedDate &&
                      new Date(selectedDate).toLocaleTimeString('ru') !== '00:00:00' ?
                      new Date(selectedDate).toLocaleTimeString('ru') : 'nie wybrano'}</div>

                  </div>
                  <div className="__result__block__form__item">
                    <div>Imię:</div>
                    <div className="__item__content">{formik.values.firstname} {formik.values.lastname}</div>
                  </div>
                  <div className="__result__block__form__item">
                    <div>Adres:</div>
                    <div className="__item__content">{formik.values.adress}</div>
                  </div>
                  <div className="__result__block__form__item">
                    <div>Telefon:</div>
                    <div className="__item__content">{formik.values.phone}</div>
                  </div>
                  <div className="__result__block__form__item">
                    <div>Email:</div>
                    <div className="__item__content">{formik.values.email}</div>
                  </div>
                  <div className="__result__block__form__item item-godzin">
                    <div>Przewidywany czas wykonywania usługi:</div>
                    <div className="__item__content">{mamyChasy()} gdz</div>
                  </div>
                  <div className="__block__form__btn promocode-wrapp">
                    {status === 2 && (
                      <span>Kod promocyjny jest nieprawidłowy!</span>
                    )}

                    {status === 1 && (
                      <span className='promocode-green-text'>Zniżką {discount}%</span>
                    )}
                    <Input className={status === 1 ? 'promocode promocode-green' : (status === 2 ? 'promocode promocode-red' : 'promocode')}
                      value={valuePromocode.toUpperCase()}
                      onChange={(event) => setValuePromocode(event.target.value)}
                      type="text" name='promocyjny' placeholder='Kod promocyjny' />
                    <button type='button' onClick={handleButtonPromocodeClick} className={valuePromocode.length !== 0 ? 'promocode-btn' : 'promocode-btn-disabled'}>Użyj</button>
                  </div>
                  <label className="check">
                    <Input
                      className="check-form"
                      type="checkbox"
                      checked={isCheckedVat}
                      onChange={handleCheckboxChangeVat}
                    />
                    <span className="custom-checkbox-form"></span>
                    <span>Potrzebuję fakturę VAT</span>
                  </label>
                  {isCheckedVat &&

                    <label className='vat-wrapp'>
                      <Input
                        name="vat"
                        type='text'
                        className={`vat ${formik.touched.vat && formik.errors.vat ? 'error-input' : ''}`}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only digits and limit to 10 characters
                          if (/^\d*$/.test(value) && value.length <= 10) {
                            formik.setFieldValue('vat', value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.vat}
                      />
                      {formik.touched.vat && formik.errors.vat ? (
                        <span className="error-message">{formik.errors.vat}</span>
                      ) : null}
                    </label>
                  }

                  <div className="check">
                    <label className="check-label">
                      <Input className='check-form' type="checkbox" checked={isChecked} onChange={handleCheckboxChangeAgree} />
                      <span className="custom-checkbox-form"></span>
                    </label>

                    <span>Zapoznałem się z <button onClick={handleOpenModal} className='modal-btn-agree' type='button'>Regulaminem</button>
                      oraz <button onClick={handleOpenModalPrivacy} className='modal-btn-agree' type='button'>Polityką prywatności</button>
                      i akceptuję ich warunki</span>
                  </div>

                  <div className="__block__form__btn">
                    <input type="submit"
                      // onClick={sendMail} 
                      name="form-action" value="Wyślij"
                      className={validateForm() ? 'submitAvalible' : 'submitDisables'}

                    />
                  </div>
                  <div className="val-mess val-mess-min">
                    * Minimalne zamówienie od 120 pl
                  </div>
                  <div className="val-mess">{formMess()}</div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className='block-form__bottom' id='form-bottom'>
            <span>“Bardzo ważne są dla nas długotrwałe relacje”</span>
          </div>
          <div className='form-back-bottom'></div> */}
        </div>

        {statusForm === 2 &&
          <div className='modal-form-wrapp'>
            <Modal isOpen={isOpenModalSuccess} setIsOpen={setIsOpenModalSuccess}>
              <div className='form-modal-text'>
                <span>Wiadomość wysłana. Skontaktujemy się z Tobą wkrótce.</span>
                <button className='form-modal-btn-success'
                  onClick={handleCloseModalSuccess}
                >Dobrze</button>
              </div>
            </Modal>
          </div>

        }

        {statusForm === 3 &&
          <div className='modal-form-wrapp'>
            <Modal isOpen={isOpenModalError} setIsOpen={setIsOpenModalError}>
              <div className='form-modal-text'>
                <span>Serwis jest tymczasowo niedostępny. Proszę spróbować ponownie później.</span>
                <button onClick={() => setIsOpenModalError(false)}>Dobrze</button>
              </div>
            </Modal>
          </div>
        }

        {statusForm === 4 &&
          <div className='modal-form-wrapp'>
            <Modal isOpen={isOpenModalErrorDate} setIsOpen={setIsOpenModalErrorDate}>
              <div className='form-modal-text'>
                <span>Wybrany termin jest niedostępny, proszę wybrać inny termin.</span>
                <button onClick={() => setIsOpenModalErrorDate(false)}>Dobrze</button>
              </div>
            </Modal>
          </div>
        }

        <ModalReglament isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} handleCloseModal={handleCloseModal} />
        <ModalPrivacyPolicy isOpenModal={isOpenModalPrivacy} setIsOpenModal={setIsOpenModalPrivacy} handleCloseModal={handleCloseModalPrivacy} />
      </div>
    </>
  )
}
