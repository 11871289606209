import React from 'react';
import './App.styles.scss';
import { Layout } from './components/Layout/Layout';
import { Route, Routes } from 'react-router-dom';
import { HomePages } from './pages/HomePages';
import { ForCompanies } from './pages/ForCompanies';

export function App() {
  return (
      <div className="App">
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<HomePages />} />
            <Route path='/for-companies' element={<ForCompanies />} />
          </Route>
        </Routes>
      </div>

  );
}
