import { useState, useRef } from 'react';
import { ModalReglament } from '../Modal/ModalReglament';
import { ModalPrivacyPolicy } from '../Modal/ModalPrivacyPolicy';
import { FileInput } from '../FileInput/FileInput';
import { Modal } from '../Modal/Modal';
import { useContactFormClient } from './hooks';
import { useMask } from '../../hooks/phone-mask/use-mask';
import { Input } from '../../shared/input/Input';
import { Loader } from '../../shared/loader/loader';
import './FormForCompanies.scss';

export const FormForCompanies = () => {

  const {
    formik,
    inputPhone,
    setInputPhone,
    valueText,
    setValueText,
    isChecked,
    setIsChecked,
    file,
    setFile,
    errorMes, setErrorMes,
    fileSize, setFileSize,
    isFocused,
    setIsFocused,
    loading,
    statusForm,
    setStatusForm,
    errorMesForm,
    isOpenModalSuccess,
    isOpenModalError,
    setIsOpenModalSuccess,
    setIsOpenModalError,
  } = useContactFormClient();

  const textareaRef = useRef(null);

  const handleChangeText = (event) => {
    setValueText(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleCheckboxChangeAgree = () => {
    setIsChecked(!isChecked);
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const [isOpenModalPrivacy, setIsOpenModalPrivacy] = useState(false);
  const handleOpenModalPrivacy = () => setIsOpenModalPrivacy(true);
  const handleCloseModalPrivacy = () => setIsOpenModalPrivacy(false);

  const handleCloseSuccessModal = () => {
    setIsOpenModalSuccess(false);
    window.location.reload();
  };

  const formMess = () => {
    let formMessVar = '';
    if (validateForm() && errorMes === '') {
      formMessVar = 'mogę wysłać';
    }
    else if (errorMes) {
      formMessVar = errorMes;
    }
    else {
      formMessVar = 'wypełnij pola';
    }
    return formMessVar;
  }


  const validateForm = () => {
    if (isChecked && formik.values.email !== ''
      &&
      formik.values.phone !== '' && formik.values.phone.length === 15 && formik.values.name !== '') {
      return 1;
    } else {
      return 0;
    }
  }


  const country = 'PL';
  const inputCode = '+48 ';
  const handlePhoneChange = (value) => {
    formik.setFieldValue('phone', value.length !== 0 ? inputCode + value : '');
  };
  const { onPhoneInput, onPhoneKeyDown, onPhoneClick, onPhoneFocus, onPhonePaste } = useMask(
    country,
    inputCode,
    setInputPhone,
    handlePhoneChange);

  return (
    <section className="section-contact" id="skontaktuj">
      {loading && <Loader loading={loading} />}
      <div className="container">
        <div className="section-contact__wrapp">
          <h4>Skontaktuj się z nami</h4>
          <a href={"mailto:bialoklining@gmail.com"} className="section-contact__link">
            <span>Email: bialoklining@gmail.com</span>
          </a>
          <form className='section-contact__form' onSubmit={formik.handleSubmit}>
            <div className='section-contact__form-input-wrapp'>
              <Input
                name="name"
                type='text'
                className={formik.touched.name && formik.errors.name ? 'section-contact__form-input error-input' : 'section-contact__form-input'}
                placeHolder='Imię*'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <span className="error-message">{formik.errors.name}</span>
              ) : null}
            </div>
            <div className='section-contact__form-input-wrapp'>
              <Input
                name="email"
                type='email'
                placeHolder='E-mail*'
                className={formik.touched.email && formik.errors.email ? 'section-contact__form-input error-input' : 'section-contact__form-input'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <span className="error-message">{formik.errors.email}</span>
              ) : null}
            </div>
            <div className='section-contact__form-input-wrapp'>
              <Input
                name="phone"
                type='tel'
                className={formik.touched.phone && formik.errors.phone ? 'section-contact__form-input error-input' : 'section-contact__form-input'}
                placeHolder='Telefon*'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={isFocused ? inputCode + inputPhone : ''}
                maxLength={15}
                onInput={onPhoneInput}
                onKeyDown={onPhoneKeyDown}
                onPaste={onPhonePaste}
                onClick={onPhoneClick}
                onFocus={(e) => { setIsFocused(true); onPhoneFocus(e) }}
                autoComplete="off-phone-client"
              />
              {formik.touched.phone && formik.errors.phone ? (
                <span className="error-message">{formik.errors.phone}</span>
              ) : null}
            </div>

            <textarea
              ref={textareaRef}
              name='text'
              value={valueText}
              onChange={handleChangeText}
              className="section-contact__textarea"
              placeholder='Tekst'
            />

            <FileInput
              file={file}
              setFile={setFile}
              name='attachment'
              setErrorMes={setErrorMes}
              errorMes={errorMes}
              setFileSize={setFileSize}
            />
            <div className='wrapp-check'>
              <div className="check">
                <label className="check-label">
                  <Input className='check-form' type="checkbox" checked={isChecked} onChange={handleCheckboxChangeAgree} />
                  <span className="custom-checkbox-form"></span>
                </label>

                <span>Zapoznałem się z <button onClick={handleOpenModal} className='modal-btn-agree' type='button'>Regulaminem</button>
                  oraz <button onClick={handleOpenModalPrivacy} className='modal-btn-agree' type='button'>Polityką prywatności</button>
                  i akceptuję ich warunki</span>
              </div>
            </div>

            <div className="form__btn">
              <input type="submit" name="form-action" value="Wyślij" className={validateForm() ? 'submitAvalible' : 'submitDisables'} />
            </div>
            <div 
            // className="val-mess"
            className={errorMes !== '' ? 'val-mess val-mess-error' : 'val-mess'}

            >{formMess()}</div>
          </form>
        </div>
      </div>
      {statusForm === 2 &&
        <div className='modal-form-wrapp'>
          <Modal isOpen={isOpenModalSuccess} setIsOpen={setIsOpenModalSuccess}>
            <div className='form-modal-text'>
              <span>Wiadomość wysłana. Skontaktujemy się z Tobą wkrótce.</span>
              <button className='form-modal-btn-success'
                onClick={handleCloseSuccessModal}
              >Dobrze</button>
            </div>
          </Modal>
        </div>

      }

      {statusForm === 3 &&
        <div className='modal-form-wrapp'>
          <Modal isOpen={isOpenModalError} setIsOpen={setIsOpenModalError}>
            <div className='form-modal-text'>
              <span>Serwis jest tymczasowo niedostępny. Proszę spróbować ponownie później.</span>
              <button onClick={() => setIsOpenModalError(false)}>Dobrze</button>
            </div>
          </Modal>
        </div>
      }
      <ModalReglament isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} handleCloseModal={handleCloseModal} />
      <ModalPrivacyPolicy isOpenModal={isOpenModalPrivacy} setIsOpenModal={setIsOpenModalPrivacy} handleCloseModal={handleCloseModalPrivacy} />
    </section>
  )
}