import React, { useEffect } from "react";
import { AdditionalServicesIcon } from "../../Icon/AdditionalServicesIcon";
import { getDodatkowe } from "../../helpers/Api";
import { CardServices } from "../../../shared/card-services/CardServices";

export function AdditionalServices({ dodatkowe, checkboxes, setDodatkowe, setCheckboxes }) {

  useEffect(() => {
    getDodatkowe().then((data) => {
      setDodatkowe(data);
      setCheckboxes(
        data.reduce((acc, item) => {
          acc[item.id] = false;
          return acc;
        }, {}));
    });
  }, [setCheckboxes, setDodatkowe]);

  const handleCheckboxChange = (id) => {
    setCheckboxes(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const isInputEmpty = Object.values(checkboxes).every(value => !value);

  return (
    <div className="__osobiste__block __block__okna">
      <CardServices
        isInputEmpty={isInputEmpty}
        title='Usługi dodatkowe'
      >
        <AdditionalServicesIcon />
      </CardServices>
      <div className="__osobiste__block__right">
        <div className="mycie_dodatkove">
          {dodatkowe.map((option) => (
            <label className="block__okna-label" key={option.id}>
              <input
                className="checkbox-input"
                type="checkbox"
                checked={checkboxes[option.id]}
                onChange={() => handleCheckboxChange(option.id)}
              />
              <span className="custom-checkbox"></span>
              <span className="checkbox-txt">{option.name}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  )
}