import React, { useEffect, useRef, useState } from "react";
import { getLocale } from "../../helpers/Api";
import './SelectCity.scss';


export function SelectCity({ miasto, setMiasto, selectedMiasto, setSelectedMiasto }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        getLocale().then((data) => {
            setMiasto(data);
        });
    }, [setMiasto, setSelectedMiasto]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectChange = (selectedValue) => {
        setSelectedMiasto(selectedValue);
        setIsOpen(false);
    };


    return (
        <div className="block-form__form__left__osobiste">
            <h5>Miasto <span>(wymagane)</span></h5>
            <div className="miasto-dropdown" ref={dropdownRef}>
                <div className="dropdown-header" onClick={handleToggleDropdown}>
                    <div className='dropdown-header__subtitle'>{selectedMiasto.name ? selectedMiasto.name : 'Wroclaw'}</div>
                    <span className={`city__svg ${!isOpen ? 'city__svg--up' : 'city__svg--down'}`}>
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.237154 0.411437C0.395257 0.254984 0.582451 0.176758 0.798736 0.176758C1.0146 0.176758 1.20158 0.254984 1.35968 0.411437L5.9921 4.99551L10.6403 0.395792C10.7879 0.249769 10.9723 0.176758 11.1937 0.176758C11.415 0.176758 11.6047 0.254984 11.7628 0.411437C11.9209 0.56789 12 0.75313 12 0.967158C12 1.18077 11.9209 1.3658 11.7628 1.52225L6.43478 6.77907C6.37154 6.84165 6.30303 6.88609 6.22925 6.91237C6.15547 6.93824 6.07642 6.95117 5.9921 6.95117C5.90777 6.95117 5.82872 6.93824 5.75494 6.91237C5.68116 6.88609 5.61265 6.84165 5.54941 6.77907L0.221344 1.50661C0.073781 1.36058 1.19727e-08 1.18077 9.42543e-09 0.967158C6.87318e-09 0.753131 0.079051 0.56789 0.237154 0.411437Z" fill="#4280BB" />
                        </svg>

                    </span>
                </div>
                {isOpen && (
                    <ul className="dropdown-list">
                        {miasto.map((city) => (
                            <li
                                key={city.id}
                                value={city.name}
                                onClick={() => handleSelectChange(city)}
                                className={selectedMiasto.name === city.name ? 'selected' : ''}
                            >
                                {city.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}
