import React, { useEffect, useState } from "react";
import { PremisesCleaningIcon } from "../../Icon/PremisesCleaningIcon";
import { getSprzatanie } from "../../helpers/Api";
import { BlueMarker, WhiteMarker } from "./MarkerIcon";
import { CardServices } from "../../../shared/card-services/CardServices";

export function PremisesCleaning({ premisesCleaning, setPremisesCleaning, priceOne, setPriceOne, changeUslugiFirstBlock, setChangeUslugiFirstBlock }) {

  useEffect(() => {
    getSprzatanie().then((data) => {
      setPremisesCleaning(data);
    });
  }, [setPremisesCleaning]);

  const [showFirst, setShowFirst] = useState(true);
  const [showSecond, setShowSecond] = useState(false);

  const toggleShowShowFirst = () => {
    setShowFirst(!showFirst);
    setShowSecond(false);
    setChangeUslugiFirstBlock(0);
  };

  const toggleShowShowSecond = () => {
    setShowSecond(!showSecond);
    setShowFirst(false);
    setChangeUslugiFirstBlock(1);
  };

  useEffect(() => {
    if(priceOne === "") {
      setChangeUslugiFirstBlock(0);
      setShowFirst(true)
      setShowSecond(false);
    }
    // Set changeUslugiFirstBlock to 0 when the component mounts
    setChangeUslugiFirstBlock(0);
  }, [priceOne, setChangeUslugiFirstBlock]);

  const isInputEmpty = priceOne === '';

  return (
    <div className="__osobiste__block __block__yborka">
      <CardServices
        isInputEmpty={isInputEmpty}
        title='sprzątanie lokali'
      >
        <PremisesCleaningIcon />
      </CardServices>
      <div className="__osobiste__block__right sprzątanie-wrapp">
        {premisesCleaning.map((option) => {
          if (option.id === 1) {
            return (
              <div key={option.id} className="sprzątanie-top">
                <span>{option.name}:</span>
                <div className="sprzątanie-top-input-wrapp">
                  <input type="number" min="20" step="1" max="2000"
                    value={priceOne}
                    onChange={event => {
                      setPriceOne(event.target.value);
                    }}

                    pattern="[1-9][0-9]*"
                    onKeyPress={(event) => {
                      if (event.key === '0' && event.target.value.length === 0) {
                        event.preventDefault();
                      }
                    }}

                    onBlur={(event) => {
                      const inputValue = parseInt(event.target.value);

                      if ((inputValue >= 1 && inputValue <= 19) 
                        // || isNaN(inputValue)
                      ) {
                        setPriceOne(20);
                      } else {
                        setPriceOne(event.target.value);
                      }
                    }}
                  />
                  <p className="placeholder">m2</p>
                </div>
              </div>
            );
          } else if (priceOne !== '' && (option.id === 2 || option.id === 3)) {
            return (
              <div className="sprzątanie-lokali" key={option.id}>
                <div
                  // onClick={() => setChangeUslugiFirstBlock(option.id === 2 ? 0 : 1)}
                  // onClick={event => setChangeUslugiFirstBlock(event.target.value = option.id === 2 ? 0 : 1)}
                  className={`sprzątanie-lokali__item${option.id === 3 ? ' sprzątanie-lokali__three' : (option.id === 2 ? ' sprzątanie-lokali__two' : '')}`}
                >
                  {option.id === 2 && (
                    <label className='radio__label'>
                      <input className='radio__input' type="radio" checked={showFirst} onChange={toggleShowShowFirst} />
                      <h6>{option.name}</h6>
                    </label>
                  )}
                  {option.id === 3 && (
                    <label className='radio__label'>
                      <input className='radio__input' type="radio" checked={showSecond} onChange={toggleShowShowSecond} />
                      <h6>{option.name}</h6>
                    </label>
                  )}
                </div>
              </div>
            );
          }
          return null;
        })}
        {priceOne !== '' && (
        
       <>
          {changeUslugiFirstBlock === 0 && (
            <ul className="first-block__list">
              <li>
                <BlueMarker />
                <span>Wycieranie drzwi na mokro i sucho;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Usuwanie kurzu z powierzchni otwartych i łatwo dostępnych;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie frontów szaf i szafek do 2m wysokości;</span>
              </li>
              <li>
                <WhiteMarker />
                <span>Mycie frontów i góry szaf i szafek powyżej 2m;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie mebli i sprzętów AGD z zewnątrz;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkurzanie i mycie podłóg bez odsuwania mebli;</span>
              </li>
              <li>
                <WhiteMarker />
                <span>Odkurzanie i mycie podłóg z odsuwaniem mebli;</span>
              </li>
              <li>
                <WhiteMarker />
                <span>Usuwanie kurzu z opraw oświetleniowych i lamp sufitowych;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie parapetów wewnętrznych i grzejników;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie luster;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Czyszczenie zlewu, umywalki, wanny, kabiny prysznicowej, muszli klozetowej, bidetu;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkamienianie kabiny prysznicowej;</span>
              </li>
              <li>
                <WhiteMarker />
                <span>Mycie glazury łazienkowej (bez doczyszczania fug);</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie i polerowanie armatury;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie blatów, kuchenki/płyty indukcyjnej, glazury kuchennej;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkurzanie mebli tapicerowanych;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkurzanie dywanów i wykładzin;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Wstawienie brudnych naczyń do zmywarki;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Opróżnienie kosza na śmieci;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Usunięcie pajęczyn.</span>
              </li>
            </ul>
          )}
          {changeUslugiFirstBlock === 1 && (
            <ul className="first-block__list">
              <li>
                <BlueMarker />
                <span>Wycieranie drzwi na mokro i sucho;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Usuwanie kurzu z powierzchni otwartych i łatwo dostępnych;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie frontów szaf i szafek do 2m wysokości;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie frontów i góry szaf i szafek powyżej 2m;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie mebli i sprzętów AGD z zewnątrz;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkurzanie i mycie podłóg bez odsuwania mebli;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkurzanie i mycie podłóg z odsuwaniem mebli;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Usuwanie kurzu z opraw oświetleniowych i lamp sufitowych;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie parapetów wewnętrznych i grzejników;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie luster;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Czyszczenie zlewu, umywalki, wanny, kabiny prysznicowej, muszli klozetowej, bidetu;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkamienianie kabiny prysznicowej;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie glazury łazienkowej (bez doczyszczania fug);</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie i polerowanie armatury;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Mycie blatów, kuchenki/płyty indukcyjnej, glazury kuchennej;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkurzanie mebli tapicerowanych;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Odkurzanie dywanów i wykładzin;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Wstawienie brudnych naczyń do zmywarki;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Opróżnienie kosza na śmieci;</span>
              </li>
              <li>
                <BlueMarker />
                <span>Usunięcie pajęczyn.</span>
              </li>
            </ul>
          )}
       </>
      )
      }
      </div>
    </div >
  )
}
